import { render, staticRenderFns } from "./VeranstaltungsuebergreifendeAnwesenheitsuebersicht.vue?vue&type=template&id=064dabf0&"
import script from "./VeranstaltungsuebergreifendeAnwesenheitsuebersicht.vue?vue&type=script&lang=js&"
export * from "./VeranstaltungsuebergreifendeAnwesenheitsuebersicht.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports