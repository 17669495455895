<template>
  <div>
    <notifications></notifications>

    <navbar :docsName="docsName" />

    <div class="container-fluid row">
      <div class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 block br-t-l-0 mb-3">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel"
            ></head-menu>

            <div class="row mt-2 d-flex">
              <zertifikate
                :kurzveranstaltungid="kurzveranstaltungid"
                :selectedObjects="selectedObjects"
              ></zertifikate>
              <div class="ml-auto mr-2">
                <button class="mr-3 btn btn-primary" @click="abbrechen">
                  <span>{{ $t("global.back") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row ml-0 w-100-p">
        <div class="col-12 block belegliste text-break">
          <div class="belegliste" v-if="loaded">
            <b-table
              class="w-100-p"
              no-border-collapse
              responsive
              sticky-header="1000px"
              ref="selectableTable"
              small
              tbody-tr-class="item"
              :items="participants"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              show-empty
              selectable
              select-mode="range"
            >
              <template #cell()="data">
                <div v-if="data.value === ''">-</div>
                <div v-else>{{ data.value }}</div>
              </template>

              <template #head(selected)="header">
                <b-form-checkbox
                  ref="headerCheckbox"
                  v-model="header.selected"
                  @input="selectAllRows(header)"
                ></b-form-checkbox>
              </template>

              <template #cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>

              <template #cell(details)="row">
                <b-button
                  @click="row.toggleDetails"
                  class="m-0"
                  variant="outline-primary"
                  size="sm"
                >
                  <font-awesome-icon
                    :icon="
                      row.detailsShowing ? 'fa-chevron-up' : 'fa-chevron-down'
                    "
                  />
                </b-button>
              </template>
              <template #row-details="row">
                <div class="p-2">
                  <b-table
                    thead-class="z"
                    tbody-tr-class="item"
                    small
                    responsive
                    sticky-header
                    :items="row.item.registrations"
                    :fields="fieldsDetails"
                    :busy="isBusy"
                    :sort-by.sync="sortByDetails"
                    show-empty
                    sort-icon-left
                    selectable
                    select-mode="range"
                    thead-tr-class="pointer no-bg"
                    striped
                  >
                    <template #cell()="data">
                      <div v-if="data.value === ''">-</div>
                      <div v-else>{{ data.value }}</div>
                    </template>

                    <template #cell(modul)="row">
                      <div>{{ row.item.translation }}</div>
                    </template>

                    <template #table-busy>
                      <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </template>

                    <template #empty>
                      <div
                        v-if="ladeFehler"
                        class="text-center text-danger my-2"
                      >
                        <strong>{{ $t("global.errorwhileloading") }}</strong>
                      </div>
                      <div
                        v-if="!ladeFehler"
                        class="text-center text-primary my-2"
                      >
                        <strong>{{ $t("global.nodataavailable") }}</strong>
                      </div>
                    </template>
                  </b-table>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import Api from "@/AppApi";
import { BSpinner } from "bootstrap-vue";

import page from "@/mixins/Page";

import Zertifikate from "@/components/Bildung/Kurzveranstaltung/Zertifikate.vue";

export default {
  name: "VeranstaltungsuebergreifendeAnwesenheitsuebersicht",
  components: {
    HeadMenu,
    Navbar,
    BSpinner,
    Zertifikate,
  },
  mixins: [page],
  props: {
    kurzveranstaltungid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      selectedIds: [],
      selectedObjects: [],
      selectAll: null,
      loaded: true,
      sortBy: "full_name",
      sortDesc: false,
      sortByDetails: "course_categorie.sort",
      navbarTitel: this.$t("global.crosseventattendanceoverview"),
      items: {},
    };
  },
  computed: {
    fields() {
      const nameField = {
        key: "full_name",
        label: this.$t("global.name"),
        sortable: true,
      };

      const average_attendance = {
        key: "average_attendance",
        label: this.$t("global.totalattendance"),
        sortable: true,
      };

      const details = {
        key: "details",
        label: this.$t("global.modules"),
      };

      const selected = { key: "selected", label: "", tdClass: "selection" };

      return [selected, nameField, average_attendance, details];
    },
    fieldsDetails() {
      return [
        {
          key: "modul",
          sortable: true,
          label: this.$t("global.module"),
        },
        {
          key: "registration_state",
          sortable: true,
          label: this.$t("global.Registrationstatus"),
        },
        {
          key: "average_attendance",
          sortable: true,
          label: this.$t("global.dashboard_presence"),
        },
      ];
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.getNotenuebersicht();
  },
  methods: {
    async getNotenuebersicht() {
      this.isBusy = true;

      await Api.get("anwesenheiten/bewertunguebersicht", {
        params: {
          id: this.kurzveranstaltungid,
        },
      })
        .then((response) => {
          this.overview = response.data?.overview;
          this.participants = response.data?.participants;
          this.ladeFehler = false;
        })
        .catch((e) => {
          console.log(e);
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    abbrechen() {
      this.$router.push({
        name: "Kurzveranstaltung",
        params: { kurzveranstaltungid: this.kurzveranstaltungid, anzeige: 7 },
      });
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((item) => {
          this.$set(item, "selected", true);
        });
        this.selectedObjects = this.$refs.selectableTable.items;
        this.selectedIds = this.$refs.selectableTable.items.map(
          (item) => item.gpid
        );
      } else {
        this.$refs.selectableTable.items.forEach((item) => {
          this.$set(item, "selected", false);
        });
        this.selectedIds = [];
        this.selectedObjects = [];
      }

      this.selectAll = Date.now();
    },
    onRowSelected() {
      if (this.selectAll) {
        let timeDiff = Date.now() - this.selectAll;
        if (timeDiff < 100) return;
      }
      this.selectedObjects = this.$refs.selectableTable.items.filter(
        (item) => item.selected
      );

      this.selectedIds = this.selectedObjects.map((item) => item.gpid);
    },
  },
};
</script>
